import { graphql, navigate, PageProps } from 'gatsby';
import { FC, useEffect, useState } from 'react';

import Registration from '../components/registration';
import Seo from '../components/seo/seo';

import { isBrowser } from '../lib/helpers';
import {
  getReferralSession,
  handleSessionStorage,
  REFERRALS,
  useReferralLink,
} from '../lib/referrals';
import { getParams } from '../lib/urls';

interface RegisterPageProps extends PageProps {
  data: {
    allContentfulRegistrationFlow: {
      nodes: Queries.ContentfulRegistrationFlow[];
    };
  };
}

const filterDataBySlug = ({
  data,
  slug = 'register',
}: {
  data: RegisterPageProps['data'];
  slug?: string;
}) => {
  return data && data.allContentfulRegistrationFlow?.nodes?.find(element => element?.slug === slug);
};

const RegisterPage: FC<RegisterPageProps> = ({ data, location }) => {
  const defaultData = filterDataBySlug({ data });
  const { c: campaign, daveTag } = getParams(location?.search);
  const [registrationData, setRegistrationData] = useState(defaultData);
  const [storedCampaign, setStoredCampaign] = useState('');
  const [storedDaveTag, setStoredDaveTag] = useState('');

  useReferralLink({ campaign, daveTag });

  useEffect(() => {
    if (isBrowser) {
      const { sessionCampaign, sessionDaveTag } = getReferralSession();

      if (sessionCampaign && sessionDaveTag && !campaign && !daveTag) {
        navigate(`/register?daveTag=${sessionDaveTag}&c=${sessionCampaign}`, { replace: true });
      }

      handleSessionStorage({ campaign, daveTag, setStoredCampaign, setStoredDaveTag });
    }
  }, [campaign, daveTag]);

  useEffect(() => {
    if (storedCampaign) {
      const contentfulData = filterDataBySlug({ data, slug: storedCampaign });
      // If the campaign is not found, default to the boost campaign
      setRegistrationData(contentfulData ?? filterDataBySlug({ data, slug: REFERRALS.BOOST }));
    }
  }, [storedCampaign, data]);

  return (
    registrationData && (
      <main className="main">
        <Seo title="Dave - Banking for Humans" pageName="register" />
        <Registration
          data={registrationData}
          location={location}
          storedCampaign={storedCampaign ?? ''}
          storedDaveTag={storedDaveTag ?? ''}
        />
      </main>
    )
  );
};

export const query = graphql`
  query registrationQuery {
    allContentfulRegistrationFlow {
      nodes {
        formHeadline
        qrCode {
          filename
          url
        }
        referralTerms {
          slug
        }
        slug
        termsLinkButton {
          path
          text
        }
        termsModal {
          ...RichTextModule
        }
        valuesPanelTitle
        valuesPanelItems {
          itemHeading
          itemSubheading
          itemBulletImage {
            filename
            url
            description
          }
        }
      }
    }
  }
`;

export default RegisterPage;
